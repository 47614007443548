@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

* {
    font-family: 'Montserrat';
}

body {
    --ion-toolbar-background: var(--ion-header-background);
    --ion-toolbar-background-rgb: var(--ion-color-primary-rgb);

    --ion-toolbar-color: var(--ion-color-primary-contrast);
    --ion-toolbar-color-rgb: var(--ion-color-primary-contrast-rgb);
}

body > ion-title {
    --ion-text-color: var(--ion-color-primary-contrast);
    --ion-text-color-rgb: var(--ion-color-primary-contrast-rgb);
}

ion-header {
    background: var(--ion-header-background);
}

amplify-s3-image {
    --height: 64px;
    --width: 64px;
}

ion-content {
    --ion-background-color: var(--ion-color-background);
}

ion-toast {
    --border-radius: 4px !important;
}
