/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
    --ion-title-color: #f4f4f4;
    --ion-text-color: #8ea2ab;
    --ion-text-muted-color: #cecece;
    --ion-text-muted-color-2: #7a8c93;
    --ion-text-muted-color-3: #b5b8b9;
    --ion-text-input-color: #878888;
    --ion-yellow-text-color: #f2c13e;
    --ion-yellow-color: #fdca40;
    --ion-text-color-rgb: 0, 0, 0;
    --ion-blue-button-color: #4a90e2;
    --ion-white-text-button: #f9f9f9;
    --ion-sliver-background-color: #2a2a2a;
    --ion-border-card: #707070;
    --ion-header-background: #e53d3f;
    --ion-content-dark: #0c0c0c;
    --ion-content-dark-2: #111312;
    --ion-content-dark-3: #181814;
    --ion-border: #515151;
    --ion-border-2: #31332a;
    --ion-border-input: #2d2f27;
    --ion-layout-dark: rgba(0, 0, 0, 0.5);

    --ion-active-status-color: #ed0423;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    /** primary **/
    --ion-color-primary: #be2039;
    --ion-color-primary-rgb: 190, 32, 57;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #a71c32;
    --ion-color-primary-tint: #c5364d;

    /** secondary **/
    --ion-color-secondary: #2a2a63;
    --ion-color-secondary-rgb: 42, 42, 99;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #252557;
    --ion-color-secondary-tint: #3f3f73;

    /** tertiary **/
    --ion-color-tertiary: #3880ff;
    --ion-color-tertiary-rgb: 56, 128, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #3171e0;
    --ion-color-tertiary-tint: #4c8dff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** Custom variables **/
    --content-header-height: 51px;

    /** Custom color **/
    --table-row-hover: #24292b;
    --ion-color-background: #131415;
    --ion-not-attendance: #e5e5e5;
    --ion-session-attendance: #fa909f;
    --ion-monthly-attendance: #90c7f9;
}
